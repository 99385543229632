@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;900&display=swap');

/* Reset */
*,*::before,*::after{box-sizing:border-box;}ul[class],ol[class]{padding: 0;}body,h1,h2,h3,h4,p,ul[class],ol[class],li,figure,figcaption,blockquote,dl,dd{margin:0;}body{min-height:100vh;scroll-behavior:smooth;text-rendering:optimizeSpeed;line-height:1.5;}ul[class],ol[class]{list-style:none;}a:not([class]){text-decoration-skip-ink:auto;}img{max-width:100%;display:block;}article>*+*{margin-top:1em;}input,button,textarea,select{font:inherit;}@media(prefers-reduced-motion:reduce){*{animation-play-state:paused !important;transition:none!important;scroll-behavior:auto !important;}}

body {
  width: 100vw;
  height: 100%;
  color: #2E2C2F;
  font-family: 'Lato', arial, helvetica, sans-serif;
  font-size: 16px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  padding: 15px;
}

h1 {
  font-weight: 300;
  font-size: 45px;
  line-height: 45px;
  margin: 0 0 15px;
  padding: 0;

  &.light {
    color: #F3E8EE;
  }
  
  &.dark {
    color: #2E2C2F;
  }
}

p {
  color: #aaa;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin: 0 0 15px;
  padding: 0;
  letter-spacing: .15em;
}

.container {
  width: 100%;
}

.overlay {
  background-color: rgba(255,255,225,.25);
  display: none;
  //opacity: 0;
  width: 100vw;
  height: 100vw;
  position: absolute;
  top: 0;
  left: 0;
}

.swatch {
  cursor: pointer;
  // width: calc(25% - 20px);
  width: 100%;
  background-color: #2c2b2d;
  border-radius: 10px;
  margin: 0;
  overflow: hidden;
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0 5px 20px rgba(37, 38, 40, .2);
  transition: all .25s ease-in-out;
  transform: scale(1);
  
  &:hover {
    transform: scale(1.025);
  }
    
  div {
    position: relative;
    width: 100%;
    padding: 4.5em 1em;
    display: block;
  }
  
  .info {
    padding: .5em;
    color: rgba(255,255,255,.8);
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9rem;
    margin: 0;
  }
}

.component-flex-grid-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(18, minmax(215, auto));
  grid-gap: 15px;
  max-width: 767px;
  margin: 0 auto;
  padding-inline-start: 0;
  
  @media screen and (min-width: 540px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(9, minmax(215, auto));
    max-width: 991px;
  }
  
  @media screen and (min-width: 992px) {
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, minmax(215, auto));
    max-width: 1440px;
  }

  &.swatch-list {
    margin: 0 auto;
  }
}

.component {
  &-search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    padding: 0 0 15px;

    input {
      border: 2px solid rgba(37, 39, 41, .25);
      padding: 5px 10px;
      min-width: 420px;
      background: rgba(255, 255, 255, .5);

      &.light {
        border: 2px solid rgba(255, 255, 255, .5);
        background: rgba(255, 255, 255, .25);
        color: rgba(255, 255, 255, 1);        
        
        &::-webkit-input-placeholder {
          color: rgba(255, 255, 255, .75);
          font-size: 1em;
        }

        &:focus {
          outline: none;
          border: 2px solid rgba(255, 255, 255, 1);
        }
      }
      
      &.dark {
        border: 2px solid rrgba(37, 39, 41, .25);
        background: rgba(37, 39, 41, .1);
        color: rgba(37, 39, 41, 1);
        
        &::-webkit-input-placeholder {
          color: rgba(37, 39, 41, .25);
          font-size: 1em;
        }

        &:focus {
          outline: none;
          border: 2px solid  rgba(37, 39, 41, 1);
        }
      }
    }
  }
}










.color {
  transition: all .25s ease-in-out;

  &-tropicalmorning {
    background-color: #fafa6e;
    background-image: linear-gradient(
      rgb(250, 250, 110) 0%,
      rgb(215, 241, 113) 6.66%,
      rgb(181, 232, 119) 13.32%,
      rgb(149, 221, 125) 19.98%,
      rgb(119, 209, 131) 26.64%,
      rgb(91, 196, 137) 33.3%,
      rgb(63, 183, 141) 39.96%,
      rgb(35, 170, 143) 46.62%,
      rgb(0, 156, 143) 53.28%,
      rgb(0, 141, 140) 59.94%,
      rgb(0, 127, 134) 66.6%,
      rgb(11, 113, 126) 73.26%,
      rgb(28, 99, 115) 79.92%,
      rgb(37, 85, 102) 86.58%,
      rgb(42, 72, 88) 100%);
  }

  &-sunset {
    background-image: linear-gradient(
      rgb(220, 144, 11) 0%,
      rgb(223, 99, 71) 20%,
      rgb(173, 72, 115) 40%,
      rgb(92, 69, 116) 60%,
      rgb(42, 59, 88) 80%
    );
  }

  &-calmmorning {
    background-image: linear-gradient(
      #93b0d2 0%,
      #ebbac4 20%,
      #c0b5ca 40%,
      #7788a8 60%,
      #646c7c 80%
    );
  }

  &-darksalmon {
    background-image: linear-gradient(
      #c68499 0%,
      #eeaf88 40%,
      #dd725e 80%,
      #340604 90%,
      #0c0c0c 100%
    );
  }

  &-crimson {
    background-image: linear-gradient(
      #8585d8 0%,
      #d7d8fc 20%,
      #f1bd92 60%,
      #cf6b5d 100%
    );
  }

  &-rouge {
    background-image: linear-gradient(
      #4759bb 0%,
      #a13f7c 60%,
       #af86c1 100%
    );
  }

  &-copper {
    background-image: linear-gradient(
      #554068 0%,
      #a185c9 50%,
      #e0936a 100%
    );
  }

  &-summerbreeze {
    background-image: linear-gradient(
      #3f67b5,
      #6baadf,
      #b5d1e8
    );
  }

  &-cosmic {
    background-image: linear-gradient(
      #243c6c 0%,
      #3b62b4 50%,
      #e4edf5 100%
    );
  }

  &-kashmir {
    background-image: linear-gradient(
      #443976 0%,
      #515f9e 35%,
      #674965 70%,
      #b78267 100%
    );
  }

  &-boldsunset {
    background-image: linear-gradient(
      #435de4 0%,
      #ad6051 50%,
      #efb028 100%
    );
  }

  &-firesunset {
    background-image: linear-gradient(
      #1c2b6c 0%,
      #634470 40%,
      #7a92bf 60%,
      #b66344 100%,
    );
  }

  &-ironsky {
    background-image: linear-gradient(
      #5cb1c7 0%,
      #c9a07b 50%,
      #d6d6d7 100%
    );
  }

  &-midnightfire {
    background-image: linear-gradient(
      #2a5461 0%,
      #675d65 50%,
      #cba07c 75%,
      #dd6a2b 100%
    );
  }

  &-bermuda {
    background-image: linear-gradient(
      #457497 0%,
      #7096ae 25%, 
      #e5865d 75%,
      #eeccbc 100%
    );
  }

  &-satch {
    background-image: linear-gradient(
      #74b0d0 0%,
      #c78c6f 50%,
      #b5095b 100%
    );
  }

  &-blush {
    background-image: linear-gradient(
      #5e3585 0%,
      #7b0a0b 65%,
      #b04d45 90%,
      #e57891 100%
    );
  }

  &-velvet {
    background-image: linear-gradient(
      #66519f 0%,
      #693560 70%,
      #ba6485 100%
    );
  }

  &-retrored {
    background-image: linear-gradient(
      #7c1f16 0%,
      #c96d35 60%,
      #f1d76c 100%
    );
  }

  &-snowbound {
    background-image: linear-gradient(
      #d7d7d7 0%,
      #b4948f 60%,
      #ca9489 100%
    );
  }

  &-chalkypeach {
    background-image: linear-gradient(
      #828c99 0%,
      #d7d7d7 60%,
      #eed89b 100%
    );
  }

  &-lavender {
    background-image: linear-gradient(
      #bbb0d7 0%,
      #ae6b77 75%,
      #f2d486 100%
    );
  }

  &-mulledwine {
    background-image: linear-gradient(
      #614c6b 0%,
      #b187b1 60%,
      #684fb1 100%
    );
  }

  &-amethyst {
    background-image: linear-gradient(
      #574d71 0%,
      #6a618e 25%,
      #7e84a2 50%,
      #a995b1 75%,
      #d7c6d6 100%
    );
  }

  &-evening {
    background-image: linear-gradient(
      #242a4d 0%,
      #3c4c84 100%
    );
  }

  &-mulberry {
    background-image: linear-gradient(
      #d2548e 0%,
      #e28635
    );
  }

  &-gray {
    background-image: linear-gradient(
      #95a4aa 0%,
      #e4e5e4
    );
  }

  &-calico {
    background-image: linear-gradient(
      #675f65 0%,
      #b29b95 60%,
      #e0b18f 100%
    );
  }

  &-vibrant {
    background-image: linear-gradient(#e49f1c 0%, #b5095b 100%);
  }

  &-turquoise {
    background-image: linear-gradient(#84fab0 0%, #8fd3f4 100%);
  }

  &-rose {
    background-image: linear-gradient(#f093fb 0%, #f5576c 100%);
  }

  &-midnightsea {
    background-image: linear-gradient(to top, #30cfd0 0%, #330867 100%);
  }

  &-mirror {
    background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%);
  }
  
  &-royal {
    background-image: linear-gradient(to bottom, #C6426E, #642B73);
  }
  
  &-lemonlime {
    background-image: linear-gradient(to bottom, rgb(217, 184, 42) 0%,rgb(131, 178, 27) 50%,rgb(45, 172, 11) 100%);
  }
  
  &-dawn {
    background-image: linear-gradient(45deg, rgb(252, 169, 90),rgb(162, 85, 124),rgb(71, 0, 157));
  }
  
  &-icy {
    background-image: linear-gradient(90deg, rgb(160, 222, 219),rgb(3, 165, 209));
  }
  
  &-blaze {
    background-image: linear-gradient(to bottom, rgb(183, 57, 12),rgb(229, 127, 73));
  }
  
  &-majesty {
    background-image: linear-gradient(to top, rgb(190, 90, 247),rgb(22, 91, 145));
  }
  
  &-sunrise {
    background-image: linear-gradient(to bottom, rgb(119, 209, 210) 0%,rgb(174, 202, 76) 58%,rgb(231, 139, 28) 100%);
  }
  
  &-apple {
    background-image: linear-gradient(135deg, rgb(185, 223, 103),rgb(253, 39, 50));
  }
  
  &-fierysunset {
    background-image: linear-gradient(to top, rgb(252, 108, 53),rgb(170, 18, 159));
  }
  
  &-glacier {
    background-image: linear-gradient(45deg, rgb(43, 33, 93),rgb(81, 240, 237));
  }
  
  &-breakofdawn {
    background-image: linear-gradient(to bottom,#00C4FF,#9D1BB2);
  }
  
  &-dreamy {
    background-image: linear-gradient(to top , #ff8a00, #e52e71);
  }
  
  &-greenflash {
    background-image: linear-gradient(to top, #2af598 0%, #009efd 100%);
  }
  
  &-anothersunset {
    background-image: linear-gradient(to bottom, #3f51b1 0%, #5a55ae 13%, #7b5fac 25%, #8f6aae 38%, #a86aa4 50%, #cc6b8e 62%, #f18271 75%, #f3a469 87%, #f7c978 100%);
  }
  
  &-tangerine {
    background-image: linear-gradient(to left bottom,#FA6400 ,#FFA505);
  }
  
  &-mutedorange {
    background: linear-gradient(to bottom, hsl(1,57%,56%)0%, hsl(26,57%,56%) 100%);
  }
  
  &-anotherdawn {
    background: linear-gradient(to bottom, hsl(174,56%,56%)0%, hsl(52,51%,55%) 100%);
  }
}


#stats {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  li {
    display: inline-flex;
    align-items: center;
    font-size: 18px;

    span {
      margin: 0;
      padding: 0;
      min-width: 50px;
      font-weight: 700;
    }
  
    p {
      margin: 0;
      padding: 0;
    }
  }
}
